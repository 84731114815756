<template lang="">

<div >
    <v-row class="align-center d-flex h-0">
        <v-col lg="4" sm="6" class="mx-auto" style="padding: 10px;">
            <v-container class="">
                <v-row class="d-flex justify-center">
                    <v-img position="center center" src="@/assets/img/checkmong/landing-page/02-Eisai-เช็คหมอง.png" contain max-height="100px" ></v-img>
                </v-row>
        
            </v-container>
            <v-card class="white-card">
                <br>
                <v-row class="d-flex justify-center">
                    <v-img position="center center" src="@/assets/img/checkmong/landing-page/ebook_page.png" contain max-height="260px"></v-img>
                </v-row>
                <br>
                <v-card class="ma-4" style="
                background: #F5F5F5;
                box-shadow: 4px 7px 10px rgba(230, 230, 230, 0.2);
                border-radius: 15px;">
                    <v-card-text class="pb-0" style="text-align: justify;">
                        <v-form ref="form" v-model="valid" lazy-validation style="width: 100%">
                            <v-text-field v-model="name" :rules="nameRules" label="ชื่อ-สกุล*" dense outlined required></v-text-field>
                            <v-text-field type="number" v-model="age" :rules="nameRules" label="อายุ*" dense outlined required></v-text-field>
                            <v-text-field type="number" v-model="phone" label="เบอร์โทรศัพท์" dense outlined></v-text-field>
                        </v-form>
                    </v-card-text>
                </v-card>
                <v-col cols="12">
                    <v-btn x-large block class="btn-pink" @click="downloadFile" :disabled="!is_download_btn_active">
                        <h3 style="font-size: 16px; font-weight: bold; letter-spacing: -1px; " >ดาวน์โหลด</h3>
                    </v-btn>
                </v-col>
            </v-card>

        </v-col>
    </v-row>
</div>
</template>

<script>
import {
    APIURL
} from '@/constants'
import Vue from 'vue'
export default {
    data() {
        return {
            logo: require('@/assets/img/checkmong/landing-page/02-Eisai-เช็คหมอง.png'),
            nameRules: [
                v => !!v || "โปรดกรอกข้อมูล",

            ],
            name:'',
            age:null,
            phone:'',
            valid:true,
            is_download_btn_active:false,
        };
    },
    watch: {
        name(val){
            if(val && this.age){
                this.is_download_btn_active = true;
            }
            else{
                this.is_download_btn_active = false;
            }
        },
        age(val){
            if(val && this.name){
                this.is_download_btn_active = true;
            }
            else{
                this.is_download_btn_active = false;
            }
        },
    },

    methods: {

        downloadFile() {


            console.log('ddd')
                  // Create a temporary anchor element to trigger the download
            const a = document.createElement('a');
            a.href = '/file/ebook_for_public.pdf'; // Specify the path to the file
            a.download = 'คู่มือการดูแลผู้ป่วยโรคอัลไซเมอร์ฉบับพื้นฐาน.pdf'; // Set the desired file name

            // Programmatically trigger the click event to start the download
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();

            // Clean up
            document.body.removeChild(a);
            this.submit_user_download_pdf()

        },
        validate() {
            const res = this.$refs.form.validate();
            //console.log(res)
            if (res) {
                this.submitRegister()
            }
            // 
        },
        async submit_user_download_pdf() {
            //call api check provider
            this.active_waiting = true
            this.title = 'กำลังส่งคำขอ Token สำหรับลงทะเบียน',
                this.status = 'loading'

            //console.log(this.lineid)
            //console.log(this.prefix)
            //console.log(this.first_name)
            //console.log(this.last_name)
            //console.log(this.phone_number)
            //console.log(this.mdnumber)
            //console.log(this.provider_list_id)
            //console.log(this.selected_provider.provider_name_location)

            await Vue.axios.post(APIURL + '/submit_user_download_pdf', {
                "name": this.name,
                "age": this.age,
                "phone": this.phone,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                //console.log('77777777777777777777')
                //console.log(response)
                this.res = response.data
                if (this.res.detail) {
                    this.$router.go()
                }

            });

        },

    },
    beforeCreate() {

    },
    mounted() {

    },
    created() {
    },
    beforeUpdate() {

    },
    updated() {
        //console.log('updated')

    }

};
</script>

<style scoped>

.footer-sm {
    position: absolute;
    bottom: 0;
    left: 0;
}

.btn-pink {
    background: #A84192 !important;
    color: white !important;
    border-radius: 9px !important;
    box-shadow: 4px 1px 4px rgba(201, 200, 200, 0.25) !important;
}
</style>
